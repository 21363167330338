import React, { useEffect, useState } from 'react';
import '../../assets/styles/css/categorias.css';
import '../../assets/styles/css/votacao.css';
import '../../assets/styles/css/espaco-avaliador.css';
import '../../assets/styles/css/swiper-bundle.min.css';
import '../../assets/styles/css/pop-up.css';

import categoriaService from '../../services/Categoria/categoria-service';
import trabalhoService from '../../services/trabalho/trabalho-service';
import LoadComponent from '../../components/Load';
import triagemService from '../../services/triagem/triagem-service';
import StatusUtil from '../../Util/status-util';
import AvaliacaoTrabalhoComponent from './components/avaliacao-component';
import { Avatar, Dialog, DialogContent, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import DialogTitle from '../../components/DialogTitle';
import configuracaoService from '../../services/Configuracao/configuracao-service';
import platform from 'platform';


export default function EspacoAvaliadorPage(props) {

    const [categoriaSelecionada, setCategoriaSelecionada] = useState('PI');
    const [codCategorias, setCodCategorias] = useState([
        "PI",
        "CS",
        "SS",
        "IC",
        "PG"
    ]);
    const [categorias, setCategorias] = useState([]);
    const [indexCategoria, setIndexCategoria] = useState(0);
    const [trabalhos, setTrabalhos] = useState([]);
    const [load, setLoad] = useState(false);
    const [triagem, setTriagem] = useState({
        aprovado: false,
        comentario: ''
    });
    const [popUpTriagem, setPopUpTriagem] = useState(false);
    const [trabalho, setTrabalho] = useState(null);
    const [usuario, setUsuario] = useState(null);
    const [loadTriagem, setLoadTriagem] = useState(false);
    const [popUpDetalhes, setPopUpDetalhes] = useState(false);
    const [dialogAvaliacao, setdialogAvaliacao] = useState(false);
    const [comentarios, setComentarios] = useState([]);
    const [configuracoes, setConfiguracoes] = useState([]);
    const [nivel, setNivel] = useState(0);
    const [dialogMensagem, setDialogMensagem] = useState({
        open: false,
        mensagem: ''
    })
    useEffect(() => {

        var _plataforma = platform.os.family;
        if (
            String(_plataforma).toLocaleLowerCase() === 'ios' ||
            String(_plataforma).toLocaleLowerCase() === 'android'
        ) {
            document.getElementById("navMenu").style.display = "none";
        }


        init()

    }, []);

    const init = async () => {

        var _usuario = await localStorage.getItem("usuario");
        console.log(_usuario)
        if (!_usuario) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login');
        }
        var user = JSON.parse(_usuario);

        if (user && user.perfis.filter(x => x.perfil === 'ADM' || x.perfil === 'TRI' || x.perfil === 'AV1' || x.perfil === 'AV2').length == 0) {
            await localStorage.setItem('menu', 'login');
            props.history.push('/login');
        }
        setUsuario(JSON.parse(_usuario));

        await localStorage.setItem('menu', 'categoria');
        var config = await getConfiguracao();
        await getCategorias();
        await getTrabalhos(config);
    }

    const handleopenDialogAvaliacao = (trabalho, nivel) => {
        setTrabalho(trabalho);
        setdialogAvaliacao(true);
        setNivel(nivel)
    }

    const handleCloseDialogAvaliacao = async () => {
        setTrabalho(null);
        setdialogAvaliacao(false);
        getTrabalhos(configuracoes);
    }
    const handleCategoriaSelecionada = (codCategoria) => {
        setCategoriaSelecionada(codCategoria);

        var index = codCategorias.findIndex(x => x === codCategoria);

        setIndexCategoria(index);
    }

    const getCategorias = async () => {
        const _categoriasResult = await categoriaService.categorias();

        console.log(_categoriasResult)
        setCategorias(_categoriasResult);
    }

    const getTrabalhos = async (config) => {
        setLoad(true);
        const _trabalhos = await trabalhoService.trabalhos();

        console.log(configuracoes)
        if (config.find(x => x.codigo == 'AV1')?.ativo == true || config.find(x => x.codigo == 'AV2')?.ativo == true)
            setTrabalhos(_trabalhos.filter(x => x.status === 'PA' || x.status === 'SA'));
        else
            setTrabalhos(_trabalhos);

        setLoad(false);

    }

    const getComentarios = async (idTrabalho) => {
        const response = await triagemService.detalhes(idTrabalho);

        if (response.status === 200) {
            var _detalhes = await response.json();
            setComentarios(_detalhes);
        }
    }

    const sliderCategorias = (num) => {
        var resultado = indexCategoria + (num);
        console.log(resultado)
        if (resultado < 0) {
            setCategoriaSelecionada('PG');
            setIndexCategoria(4)
            return;
        } else if (resultado > 4) {
            setCategoriaSelecionada('PI');
            setIndexCategoria(0)
            return;
        }
        else {
            var _codCategoria = codCategorias[resultado];
            setCategoriaSelecionada(_codCategoria);
            setIndexCategoria(resultado)
            return;
        }
    }

    const salvarTriagem = async () => {

        if (!triagem.comentario) {
            setDialogMensagem({
                open: true,
                mensagem: 'Preencha o campo Comentário'
            });

            return;
        }

        setLoadTriagem(true)
        const dataTriagem = {
            id_trabalho: trabalho.id,
            descricao: triagem.comentario,
            aprovado: triagem.aprovado,
            id_usuario: parseInt(usuario.id)
        }
        const cadastrado = await triagemService.triagem(dataTriagem);

        if (cadastrado.status === 201) {
            await getTrabalhos(configuracoes);
            closeTriagem();
        }
     
    }

    const closeTriagem = () => {
        setTriagem({
            aprovado: false,
            comentario:''
        });
        setComentarios([]);
        setPopUpTriagem(false);
        setLoadTriagem(false);

    }

    const openTriagem =  async (tralho) => {
        setTrabalho(tralho);
        await getComentarios(tralho.id);
        setPopUpTriagem(true);
        
    }

    const openDetalhes = (trabalho) => {
        setTrabalho(trabalho);
        setPopUpDetalhes(true);
    }

    const closeDetalhes = (trabalho) => {
        setTrabalho(null);
        setPopUpDetalhes(false);
    }

    const getConfiguracao = async () => {
        const config = await configuracaoService.configuracoes();
        setConfiguracoes(config);

        return config;
    }

    const closeDialogMensagem = () => {
        setDialogMensagem({
            open: false,
            mensagem: ''
        });
    }
    return (<>
        {
            dialogMensagem.open ? <Dialog open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"

            >

                <DialogTitle children={`Validação`} onClose={closeDialogMensagem}></DialogTitle>
                <DialogContent dividers>

                    <label>{dialogMensagem.mensagem}</label>

                </DialogContent>
            </Dialog> : null
        }
        {
            popUpTriagem ? <Dialog open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"
                maxWidth='xl'
            >

                <DialogTitle children={`Triagem`} onClose={() => { closeTriagem() }}></DialogTitle>
                <DialogContent dividers>

            

                    <div style={{ overflowY: 'auto', padding: '12px', height: '350px', width: '100%' }}>
                    <label><div>

                        <input type="radio" value={true} name="triagem" onChange={() => setTriagem({ ...triagem, aprovado: true })} />&nbsp;&nbsp;Aprovado &nbsp;&nbsp;
                        <input type="radio" value={false} name="triagem" onChange={() => setTriagem({ ...triagem, aprovado: false })} />&nbsp;&nbsp;Não Aprovado &nbsp;&nbsp;
          
                    </div>
                </label>
                <div>
                    <label>Comentário</label><br />
                    <textarea
                        value={triagem.comentario}
                        style={{ resize: 'none', width: '90%', height: '75px' }}
                        onChange={(e) => setTriagem({ ...triagem, comentario: e.target.value })}
                    />
                        </div>


                        <div style={{ paddingRight: '10px' }}>
                            {loadTriagem ? <LoadComponent /> : <input type="submit" value="Salvar" class="trabalho-item-btn" onClick={() => salvarTriagem()}></input>}

                        </div>

                        <Grid item xs={12}>
                            <div>

                                <List >

                                    {comentarios.map((i) => {

                                        return (
                                            <>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar alt="Remy Sharp" />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={i.autor}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"

                                                                    color="textPrimary"
                                                                >
                                                                    {i.data}
                                                                </Typography>
                                                                {` — ${i.descricao}`}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider variant="inset" component="li" />
                                            </>)
                                    })
                                    }
                                </List>

                            </div>
                        </Grid>



            </div>



                    {/*
                <div style={{ float: 'right', paddingRight: '10px' }}>
                    <input type="submit" value="Cancelar" class="trabalho-item-btn" onClick={() => closeTriagem()}></input>

                    </div>
                    */}
   


            
                </DialogContent>
            </Dialog> : false
        }
        {popUpDetalhes ?
            <Dialog open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="confirmation-dialog-title"
               
            >

                <DialogTitle children={`Detalhes`} onClose={() => { closeDetalhes() }}></DialogTitle>
                <DialogContent dividers>

                

                    <div style={{ overflowY: 'auto', padding: '12px', height: '300px', width: '100%' }}>
                    <label>Nome: {trabalho?.descricao} </label><br />
                    <label>Unidade: {trabalho?.unidade} </label><br />
                    <label>Área: {trabalho?.area} </label><br />
                    <label>Líder: {trabalho?.lider} </label><br />
                    <label>Título do trabalho: {trabalho?.titulo}</label><br />
                    <label>Status: {StatusUtil.getSatatus(trabalho?.status)}</label><br />
                        {/*
                        <div style={{ alignContent: 'center', justifyContent: 'center' }}>
                            <input type="submit" value="Fechar" class="trabalho-item-btn" onClick={() => closeDetalhes()}></input>
                        </div>
                        */}
                    </div>



               

                </DialogContent>
            </Dialog> : null
        }

        <section class="categorias">
            <h2 class="categorias-titulo">Selecione a Categoria</h2>


            <div class="container-icons-diferenciais">

                <div class="container-seta container-seta-direita" onClick={() => sliderCategorias(1)}></div>
                <div class="container-seta container-seta-esquerda" onClick={() => sliderCategorias(-1)}></div>
                <div class="seta seta-direita" onClick={() => sliderCategorias(1)}>
                    <img src="assets/img/seta-swiper.png" />
                </div>
                <div class="seta seta-esquerda" onClick={() => sliderCategorias(-1)}>
                    <img src="assets/img/seta-swiper.png" />
                </div>

                <div class="swiper-mobile swiper mySwiper">
                    <div class="swiper-wrapper">
                        {

                            indexCategoria == 0 ? (
                                <div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                                        </div>
                                        <span>Produtividade e inovação</span>
                                    </div>
                                </div>) : null
                        }
                        {
                            indexCategoria == 1 ? (<div class="swiper-slide">
                                <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                    <div class="moldura-icon">
                                        <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                                    </div>
                                    <span>Crescimento sustentável</span>
                                </div>
                            </div>) : null
                        }
                        {
                            indexCategoria == 2 ?
                                (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                                        </div>
                                        <span>Saúde e segurança</span>
                                    </div>
                                </div>) : null
                        }
                        {
                            indexCategoria == 3 ?
                                (<div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                                        </div>
                                        <span>Iniciativas corporativas</span>
                                    </div>
                                </div>
                                ) : null
                        }
                        {
                            indexCategoria == 4 ? (
                                <div class="swiper-slide">
                                    <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'}>
                                        <div class="moldura-icon">
                                            <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                                        </div>
                                        <span>Pequenas ações<br/> grandes feitos</span>
                                    </div>
                                </div>) : null
                        }
                    </div>

                </div>

                <div class="container swiper-desktop swiper">
                    <div class={categoriaSelecionada === 'PI' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PI')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'PI' ? require("../../assets/img/icones-categorias/icone-produtividade-active.png") : require("../../assets/img/icones-categorias/icone-produtividade.png")} alt="" />
                        </div>
                        <span>Produtividade e inovação</span>
                    </div>
                    <div class={categoriaSelecionada === 'CS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('CS')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'CS' ? require("../../assets/img/icones-categorias/icone-crescimento-active.png") : require("../../assets/img/icones-categorias/icone-crescimento.png")} alt="" />
                        </div>
                        <span>Crescimento sustentável</span>
                    </div>
                    <div class={categoriaSelecionada === 'SS' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('SS')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'SS' ? require("../../assets/img/icones-categorias/icone-saude-active.png") : require("../../assets/img/icones-categorias/icone-saude.png")} alt="" />
                        </div>
                        <span>Saúde e segurança</span>
                    </div>
                    <div class={categoriaSelecionada === 'IC' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('IC')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'IC' ? require("../../assets/img/icones-categorias/icone-iniciativas-active.png") : require("../../assets/img/icones-categorias/icone-iniciativas.png")} alt="" />
                        </div>
                        <span>Iniciativas corporativas</span>
                    </div>
                    <div class={categoriaSelecionada === 'PG' ? 'swiper-slide-active icon-diferenciais' : 'icon-diferenciais'} onClick={() => handleCategoriaSelecionada('PG')}>
                        <div class="moldura-icon">
                            <img src={categoriaSelecionada === 'PG' ? require("../../assets/img/icones-categorias/icone-acoes-active.png") : require("../../assets/img/icones-categorias/icone-acoes.png")} alt="" />
                        </div>
                        <span>Pequenas ações grandes feitos</span>
                    </div>
                </div>
            </div>

            <img class={"categoria-selecionado-" + categoriaSelecionada} src="assets/img/categoria-selecionado.png" />

            {load ? <div class="container container-categoria">
                <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}><LoadComponent /></div>
            </div> :

                <div class="trabalhos">
                    {trabalhos.filter(x => x.categoria.cod === categoriaSelecionada).map((tr)=>
                        <div class="trabalho-item">
                            <div class="trabalho-item-titulo">
                                {tr.titulo}
                            </div>
                            <div class="trabalho-item-titulo">
                                {tr.autor.nome}
                            </div>
                            <div class="trabalho-item-titulo">
                                Status: {StatusUtil.getSatatus(tr.status)}
                            </div>

                            <div class="trabalho-item-btn" onClick={() => openDetalhes(tr)}>
                                Detalhes
                            </div>

                            <a href={`api/anexos/${tr.id}`} download>
                                <div class="trabalho-item-btn">
                                    {categoriaSelecionada === "PG" ? "Baixar Video" : "Baixar PDF"}
                                 </div>
                            </a>
                            {tr.status === 'TR' && usuario?.perfis.filter(x =>  x.perfil === 'TRI').length > 0 ?

                                <div onClick={() => openTriagem(tr)} class="trabalho-item-btn trabalho-item-btn-avaliar">
                                    Triagem
                                </div>
                                : null
                            }
                            {tr.status === 'PA' && usuario?.perfis.filter(x => x.perfil === 'AV1').length > 0 && configuracoes.find(x => x.codigo == 'AV1')?.ativo == true ?

                                <div onClick={() => handleopenDialogAvaliacao(tr,1)} class="trabalho-item-btn trabalho-item-btn-avaliar">
                                    Avaliação
                                </div>
                                : null
                            }
                            { tr.status === 'SA' && usuario?.perfis.filter(x => x.perfil === 'AV2').length > 0 &&  configuracoes.find(x => x.codigo == 'AV2')?.ativo == true ?

                                <div onClick={() => handleopenDialogAvaliacao(tr, 2)} class="trabalho-item-btn trabalho-item-btn-avaliar">
                                    Avaliação
                                </div>
                                : null
                            }
                        </div>
                        )
                    }

              
                </div>
            }
          
        </section>

        <AvaliacaoTrabalhoComponent
            isOpen={dialogAvaliacao}
            idUsuario={usuario?.id}
            close={handleCloseDialogAvaliacao}
            trabalho={trabalho}
            nivel={nivel}
        />
    </>);
}